import { reactive, watch } from 'vue'
import { useForm } from '../../Base/useForm'
import { deepClone } from '@/utils/utils'
import { setManualId } from '@/http/manualApi'
import { useStore } from 'vuex'
import { unGetItem } from '@/http/tableApi'
import { Alert } from '@/extension/Alert/Alert'

export function useManualForm (fields, page) {
  const store = useStore()
  watch(fields, () => {
    for (let i = 0; i <= Object.keys(formData).length; i++) {
      if (Object.keys(formData)[i] !== 'name') {
        delete formData[Object.keys(formData)[i]]
      }
    }
    fields.forEach((field) => {
      if (field.action !== 'delete') {
        formData['name' + field.id] = field.name
        if (oldFormData !== formData) {
          oldFormData = deepClone(formData)
        }
      } else {
        delete formData['name' + field.id]
        delete rules['name' + field.id]
      }
    })
  })

  const formData = reactive({
    name: ''
  })

  let oldFormData = {}

  const rules = reactive({
  })

  const serverErrors = reactive({})

  const clearValue = () => {
    formData.name = ''
  }

  const formAction = async () => {
    try {
      for (const key in formData) {
        if (oldFormData[key] !== formData[key]) {
          fields.map(f => f.id === +key.slice(4) && (f.action = 'update'))
        }
      }

      fields.map((f) => {
        if (f.action === 'add') {
          f.id = null
        } else {
          f.name = formData['name' + f.id]
        }
      })

      await setManualId(fields[0].parentId,
        {
          child: fields.slice(1),
          name: fields[0].name,
          id: fields[0].parentId
        })
      await Alert.show('success', `Справочник "${fields[0].name}" успешно изменён`)
      const data = await unGetItem(true, `/references/list?page=${page}&per-page=9&sort=-published_at`)
      store.commit('item/setItems', data.data.data)
    } catch (e) {
      await Alert.show('error', `Справочник ${fields[0].name} не удалось изменить`)
    }
  }

  return {
    ...useForm(rules, formData, formAction),
    serverErrors,
    clearValue
  }
}
