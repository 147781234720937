<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div class="block__control">
    <div class="block__field">
      <VSearch
        name="searchManual"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48"
        v-model="input"
      />
    </div>
  </div>

  <VTable
    :head-row="headRow"
    :is-checkbox="false"
    url-set-rows="/references/list"
    @link="handlerLink"
  />

  <teleport to="body">
    <VModal
      :scrollY="responseField.length > 4"
      modal-inner-class="width-820"
      @close="() => {
        closeVModal(),
        clearValue()
      }"
      v-if="showVModal">
      <h4 class="modal__title mb-40">Справочник</h4>

      <form class="form" @submit.prevent="onSubmit">
        <div class="form__inner">

          <div
            v-for="{ id, parent, action } in responseField"
            :key="id">
            <div
              v-if="action !== 'delete'"
              class="field">
              <VInput
                :name="'name' + id"
                :id="'name' + id"
                v-model="formData['name' + id]"
                :errors="v$?.['name' + id]?.$errors"
                @blur="validateField"
                @input="validateField"
                :class-input="['input input-long input-placeholder-black input-border-bottom']"
              />

              <VIcon
                v-if="!parent"
                class="elements__icon no-close"
                width="38"
                height="38"
                icon-name="AccordionDelete"
                @click="deleteParameters(id)"
              />
            </div>
          </div>

          <div class="field">
            <VInput
              id="name"
              name="name"
              v-model="formData.name"
              placeholder="Добавить"
              :class-input="['input input-long input-placeholder-black input-border-bottom']"
            />
            <VIcon
              width="38"
              height="38"
              icon-name="AccordionAdd"
              @click="addParameters(formData.name), clearValue()"
            />
          </div>

          <div class="modal__buttons mt-20">
            <button
              class="btn btn-primary btn-bg-green"
              type="submit"
              :disabled="isSend"
            >
              Сохранить
            </button>
            <button
              class="btn btn-primary btn-bg-transparent"
              type="button"
              @click="() => {
                closeVModal(),
                clearValue()
              }"
            >
              Отмена
            </button>
          </div>
        </div>
      </form>
    </VModal>
  </teleport>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useManualForm } from '@/use/Form/Admin/useManualForm'
import VModal from '@/components/ui/modal/VModal'
import VSearch from '@/components/ui/form/Search/VSearch'
import VTable from '@/components/ui/table/VTable'
import VInput from '@/components/ui/form/VInput'
import VIcon from '@/components/ui/icon/VIcon'
import { getManualId } from '@/http/manualApi'
import useVModal from '@/use/Base/useVModal'
import { hashCode } from '@/utils/utils'
import { useFilter } from '@/use/Base/useFilter'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const responseField = reactive([])
    const { showVModal, closeVModal, openVModal } = useVModal()

    const headRow = [
      {
        name: '№ п/п',
        value: 'id',
        filter: true,
        decorator: {
          name: 'link'
        }
      },
      {
        name: 'Наименование справочника',
        value: 'name',
        filter: true,
        decorator: {
          name: 'link'
        }
      }
    ]

    const handlerLink = async (value) => {
      try {
        const { child, name, id } = await getManualId(value.item.id)
        responseField.length = 0
        responseField.push({
          name: name,
          id: `parent-${id}`,
          parent: true,
          parentId: id
        },
        ...child ?? [])
        openVModal()
      } catch (e) {
        await store.dispatch('setAlert', {
          type: 'error',
          description: 'Не удалось загрузить данные о справочнике'
        })
      }
    }

    const deleteParameters = (id) => {
      responseField.map(field => {
        if (field.id === id) {
          field.action = 'delete'
        }
      })
    }

    const addParameters = (value) => {
      if (!value) return
      let isHas = false
      responseField.map((field) => {
        if (field.name === value) {
          isHas = true
        }
      })
      if (isHas) return
      const hash = hashCode(value)
      responseField.push({
        id: hash,
        name: value,
        action: 'add'
      })
    }

    return {
      addParameters,
      responseField,
      ...useFilter(),
      ...useManualForm(responseField, 1),
      closeVModal,
      showVModal,
      headRow,
      handlerLink,
      deleteParameters
    }
  },
  components: {
    VSearch,
    VTable,
    VModal,
    VInput,
    VIcon
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";

.field {
  display: flex;
  justify-content: space-between;

  & > svg {
    position: relative;
    top: 10px;
  }
}
</style>
